import React from 'react';
import './App.css';
import selfPortrait from './self-portrait.JPG';

const App = () => {
  return (
    <div>
      <header>
        <h1>samay</h1>
        <h2>new york city</h2>
      </header>
      {/* Projects Section */}
      <main>
      <span>
        <p style={{ display: 'inline' }}>
          software engineer at 
          <a className="blue-link no-underline w-fit" href="https://robinhood.com/us/en/" target='_blank' rel='noopener noreferrer'>
            robinhood
          </a>,
          previously building at 
          <a className="blue-link no-underline w-fit" href="https://www.snapchat.com/" target='_blank' rel='noopener noreferrer'>
             snap 
          </a>
          , block (
          <a className="blue-link no-underline w-fit" href="https://squareup.com/us/en" target='_blank' rel='noopener noreferrer'>
            fka square
          </a>
          ), and more.
          my obsessions are film photography, playing the piano, and collecting matchboxes
        </p>
        </span>
        <p>
          <span style={{ display: 'inline' }}>get in touch: <a className="blue-link no-underline w-fit" href="mailto:hey@samay.space">email</a> or <a className="blue-link no-underline w-fit" href="https://linkedin.com/in/samaynathani" target="_blank" rel="noopener noreferrer">linkedin</a></span>
        </p>
        <section className="section">
          <img src={selfPortrait} alt="self-portrait.JPG" style={{ maxWidth: '300px', borderRadius: '4px' }} />
        </section>
        <section className="section">
          <h2>links</h2>
          <p className="projects-list">
            <a className="blue-link no-underline w-fit" href="#">resume</a>
            <a className="blue-link no-underline w-fit" href="mailto:hey@samay.space">hey@samay.space</a>
          </p>
        </section>
      </main>
      <footer>
        <h2>updated march, 2025</h2>
      </footer>
    </div>
  );
};

export default App;
